body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100dvh;
    max-height: 100dvh;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    position: relative;
}
#root {
    flex: 1;
    display: flex;
    gap: 1.5em;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
    flex-direction: column;
    gap: 2em;
}
.inputs {
    display: flex;
    gap: 1.5em;
    flex-direction: row;
}
.chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 80%;
}
.chart-wrapper {
    padding: 0.5em 1em;
    border-radius: 0.75em;
    position: relative;
    flex: 1;
    align-self: stretch;
    background: linear-gradient(to bottom, #202023, #161618);
}
.chart:not(:has(.no-data))::before {
    inset: -5px;
    position: absolute;
    content: '';
    pointer-events: none;
    border-radius: 1rem;
    border: 1px solid #26262a;
}
.no-data-span {
    color: #fc3c3c;
    font-weight: 500;
}
@media (orientation: portrait) {
    #root {
        flex-direction: column-reverse;
    }
    .inputs {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1em;
        align-self: center;
        width: 80%;
    }
    .chart {
        width: calc(100% - 10px);
        margin-top: 5px;
        height: 300px;
    }
    .chart.no-data {
        height: unset;
    }
}