.currency-input {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    padding: 1em;
    background: linear-gradient(to bottom, #202023, #161618);
    border-radius: 0.75em;
    gap: 0.5em;
    position: relative;
}
.currency-input::before {
    inset: -5px;
    position: absolute;
    content: '';
    pointer-events: none;
    border-radius: 1rem;
    border: 1px solid #26262a;
}
.currency-input-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.currency-input-currency {
    font-size: 1rem;
    font-weight: 600;
    color: #edeef1;
}
.currency-input-select {
    border: none;
    outline: none;
    padding: 4px;
    font-size: 0.8rem;
    font-weight: 550;
    background: black;
    color: #edeef1;
    border-radius: 0.5em;
}
.currency-input-input {
    border: 2px solid transparent;
    outline: none;
    padding: 0.5em 0.75em;
    font-size: 1rem;
    font-weight: 800;
    background: black;
    color: #edeef1;
    border-radius: 0.5em;
    transition-duration: 0.2s;
}
.currency-input-input:focus {
    border: 2px solid #edeef1;
}
.currency-input-input::placeholder {
    font-weight: 500;
    color: #757885;
}

@media (orientation: portrait) {
    
}