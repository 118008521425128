.history {
    display: flex;
    flex-direction: column;
    margin: 1em;
    align-items: center;
    gap: 0.5em;
}
.history > span {
    color: #3f3f48;
}
.history-container {
    max-width: 90vw;
    gap: 0.5em;
    display: flex;
    flex-direction: row-reverse;
}
.history-container > button {
    background: #161618;
    outline: none;
    border: 2px solid #202023;
    color: white;
    border-radius: 4px;
    padding: 0.5em 1em;
}